import api from './config'

const baseUrl = '/auth'

export const postSignin = async (req) => {
    const url = `${baseUrl}/signin`
    const payload = await api({
        method: 'post',
        url: url,
        data: req,
    })
    if (payload.status === 200) {
        localStorage.setItem('user-token', payload.data.token)
        return { code: 0, user: payload.data.user }
    }
}

export const checkAuthentication = async () => {
    try {
        const payload = await api({
            method: 'get',
            url: `${baseUrl}/authenticate`,
        })
        return payload
    } catch (e) {
        return e
    }
}
