import api from './config'
import { CONTENT_EVENT_STATUS } from '@/utils/enum'
const baseUrl = '/event/content'

export const checkSlugAvailability = async ({ domainCode, slug }) => {
    try {
        await api({
            method: 'get',
            url: `${baseUrl}/checkSlug`,
            params: { domainCode, slug },
        })
        return true
    } catch (e) {
        return false
    }
}

export const createEvent = async (data, status = CONTENT_EVENT_STATUS.DRAFT) => {
    const payload = await api({
        method: 'post',
        url: `${baseUrl}/`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { status },
        data,
    })
    return payload
}

export const localeList = async () => {
    const payload = await api({ method: 'get', url: `${baseUrl}/getLocale` })
    return payload.data
}

export const eventMe = async (id) => {
    const payload = await api({ method: 'get', url: `${baseUrl}/${id}` })
    return payload
}

export const updateEvent = async (id, data, status = CONTENT_EVENT_STATUS.DRAFT) => {
    const payload = await api({
        method: 'patch',
        url: `${baseUrl}/${id}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { status },
        data,
    })
    return payload
}
