<template>
    <div class="main-modal" v-if="commonStore.isModalOpen === true">
        <div class="modal-overlay" @click="commonStore.closeModal()"></div>
        <div class="modal-panel">
            <div class="modal-button-close" @click="commonStore.closeModal()">X</div>
            <EditFinancialAssets v-if="commonStore.modalName === 'EditFinancialAssets'" />
            <EditCategories v-if="commonStore.modalName === 'EditCategories'" />
        </div>
    </div>
</template>

<style lang="scss">
.main-modal {
    z-index: 10000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .modal-overlay {
        background-color: #000000aa;
        width: 100%;
        height: 100%;
    }

    .modal-panel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 40px;
        background-color: #ffffff;
        width: 100%;
        max-width: 400px;
    }

    .modal-button-close {
        cursor: pointer;
        position: absolute;
        font-size: 20px;
        top: 10px;
        right: 15px;
        transform: scaleX(1.3);
    }
}
</style>

<script>
import { useCommonStore } from '@/stores/common'
import EditFinancialAssets from './EditFinancialAssets'
import EditCategories from './EditCategories'

export default {
    name: 'MainModal',
    components: {
        EditFinancialAssets,
        EditCategories,
    },
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
}
</script>
