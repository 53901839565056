import axios from 'axios'
import config from '@/config'

const api = axios.create({
    // baseURL: config.LOCAL_ENV ? 'http://localhost:3000/api/v1/' : '/api/v1',
})

api.interceptors.request.use(
    async (config) => {
        config.baseURL = await getBaseUrl()
        const token = localStorage.getItem('user-token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        if (!Object.hasOwnProperty.call(config.headers, 'Content-Type'))
            config.headers['Content-Type'] = 'application/json'
        return config
    },
    (error) => {
        throw error
    },
)
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            localStorage.removeItem('user-token')
            // localStorage.removeItem("user-id");
            window.location.href = '/login'
        } else {
            throw error.response
        }
    },
)

function getBaseUrl() {
    let baseurl = '/api/v1'
    if (window.location.hostname === 'localhost') {
        baseurl = 'http://localhost:3000/api/v1/'
        // baseurl = 'https://dev-cms-v2.finpoints.tech/api/v1/'
    }
    return Promise.resolve(baseurl)
}

export default api
