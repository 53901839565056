import api from './config'
import { API_KEY } from '@/config'

export const getEventList = async (groupId) => {
    const url = `/public/getEventList/${groupId}`
    try {
        const payload = await api({
            method: 'get',
            url: url,
            headers: {
                apikey: API_KEY,
            },
        })
        return payload.data
    } catch (e) {
        return e
    }
}

export const getEventData = async (id) => {
    const url = `/public/getEventListById${id}?preview=true`
    try {
        const payload = await api({
            method: 'get',
            url: url,
            headers: {
                apikey: API_KEY,
            },
        })
        return payload.data
    } catch (e) {
        return e
    }
}

export const getEventDataBySlug = async (slug) => {
    const url = `/public/getEventListBySlug?preview=true&slug=${slug}`
    try {
        const payload = await api({
            method: 'get',
            url: url,
            headers: {
                apikey: API_KEY,
            },
        })
        return payload.data
    } catch (e) {
        return e
    }
}
