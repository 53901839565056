import api from './config'

const baseUrl = '/event/template'

export const templateList = async (templateGroupId) => {
    try {
        const payload = await api({
            method: 'get',
            url: `${baseUrl}/${templateGroupId}`,
        })
        return payload.data
    } catch (e) {
        return e
    }
}
