import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', {
    state: () => ({
        // Data
        dataCategories: [],
        dataFinancialAssets: [],
        dataSecurities: [],
        dataFinancialTools: [],
        arrayDatasetFinancialTools: [],
        activeCategory: {},
        activeFinancialAsset: {},
        tableColumns: [],
        isTriggerApi: false,
        userID: '',
        // UI
        sidebarVisible: '',
        sidebarUnfoldable: false,
        modalName: '',
        isModalOpen: false,
        toasts: [],
        showSidebar: true,
        listValue: [],
        loading: false,
    }),
    actions: {
        setLoading(boolean) {
            this.loading = boolean
        },
        // Data
        setDataCategories(data) {
            this.dataCategories = data
        },
        setDataFinancialAssets(data) {
            this.dataFinancialAssets = data
        },
        setDataFinancialTools(data) {
            this.dataFinancialTools = data
            this.loading = false
        },
        setTableColumns(data) {
            this.tableColumns = data
        },
        setArrayDatasetFinancialTools(data) {
            this.arrayDatasetFinancialTools = data
        },

        setUpdateValue(data) {
            this.listValue = data
            console.log(data, 'update vaasdl')
        },

        setActiveCategory(data) {
            this.activeCategory = data
        },
        setActiveFinancialAsset(data) {
            this.activeFinancialAsset = data
        },

        // UI
        openModal(name) {
            this.modalName = name
            this.isModalOpen = true
        },
        closeModal() {
            this.modalName = ''
            this.isModalOpen = false
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar
            console.log('normal toggle', this.showSidebar)
        },
        toggleUnfoldable() {
            this.sidebarUnfoldable = !this.sidebarUnfoldable
        },
        updateSidebarVisible(payload) {
            this.sidebarVisible = payload
        },
        createToast(title, content) {
            this.toasts.push({
                title: title,
                content: content,
            })
        },
        setIsTriggerApi(data) {
            this.isTriggerApi = data
        },
        setUserID(data) {
            this.userID = data
        },
        setShowSidebar(n) {
            if (typeof n == 'boolean') {
                console.log('n', n)
                this.showSidebar = n
                console.log('n after this.showSidebar', this.showSidebar)
                return
            }

            this.toggleSidebar()
            const exdays = 30

            const d = new Date()
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
            let expires = 'expires=' + d.toUTCString()
            document.cookie = 'showSidebar' + '=' + this.showSidebar + ';' + expires + ';path=/'
        },
    },
})
