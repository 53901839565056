<template>
    <div class="modal-edit-categories">
        <h2>Edit Categories</h2>
        <div class="asset-edit-list-wrapper">
            <div
                class="asset-edit-list"
                v-for="(category, index) in commonStore.dataCategories"
                :key="index"
            >
                <div class="list-display">
                    <span>{{ category.name }}</span>
                </div>
            </div>
            <div class="input-group" v-if="!editId">
                <input
                    class="input form-control"
                    placeholder="Add New Category"
                    type="text"
                    name="name"
                    v-model="inputNameAdd"
                />
                <button
                    :class="`btn-add ${inputNameAdd ? '' : 'disabled'}`"
                    @click="handleInsertCategory()"
                >
                    Add
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.modal-edit-categories {
    .asset-edit-list-wrapper {
        padding-top: 16px;
        .asset-edit-list {
            padding-bottom: 16px;
        }
        .btn-edit {
            cursor: pointer;
        }
        .input-group {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            .btn-add {
                padding: 8px 12px;
                border-radius: 0 8px 8px 0 !important;
                border: 1px solid #dddddd;
                background-color: #ffffff;
                &.disabled {
                    pointer-events: none;
                    color: #666666;
                    background-color: #dddddd;
                }
            }
        }
    }
}
</style>

<script>
import { useCommonStore } from '@/stores/common'
import { findCategories, insertCategory } from '@/api/categories'

export default {
    name: 'ModalEditCategories',
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
    methods: {
        async handleInsertCategory() {
            if (this.inputNameAdd == '') return
            const id = parseInt(this.$route.params.entityId)

            let body = {
                name: this.inputNameAdd,
                entity_id: id,
            }
            await insertCategory(body)
            await this.fetchCategories(id)
            this.inputNameAdd = ''
        },
        async fetchCategories(id) {
            const { data } = await findCategories(id)
            this.commonStore.setDataCategories(data.categories)
        },
    },
    data() {
        return {
            editId: null,
            inputNameAdd: '',
        }
    },
}
</script>
