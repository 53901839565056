import api from './config'

const baseUrl = '/pog/categories'

export const findCategories = async (id) => {
    try {
        const payload = await api({ method: 'get', url: `${baseUrl}/find/${id}` })
        return payload
    } catch (e) {
        return e
    }
}

export const insertCategory = async (data) => {
    try {
        const payload = await api({ method: 'post', url: `${baseUrl}/insert`, data: data })
        return payload
    } catch (e) {
        return e
    }
}
