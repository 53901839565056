import { defineStore } from 'pinia'
import { ref, onBeforeMount } from 'vue'
import { domainList as domainListApi } from '@/api/domain'
import { localeList as localeListApi, eventMe } from '@/api/content'
import { templateList as templateListApi } from '@/api/template'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import { minimalTimezoneSet } from '@/utils/helper'
import { CONTENT_EVENT_STATUS } from '@/utils/enum'
const defaultBasicSetting = () => ({
    id: '',
    name: '',
    slug: '',
    locale: ['0'],
    utc: minimalTimezoneSet.find((x) => x.offsetInMinute === 0),
    domain: '0',
    liveChat: 'No',
    status: CONTENT_EVENT_STATUS.DRAFT,
    startDate: DateTime.now().plus({ day: 3 }).set({ second: 0 }).toUTC(0).toISO().split('.')[0],
    endDate: DateTime.now().plus({ day: 7 }).set({ second: 0 }).toUTC(0).toISO().split('.')[0],
    localeMeta: {
        en: {
            title: '',
            description: '',
            keyword: '',
        },
    },
    layout: '0',
})

export const useEventStore = defineStore('event', () => {
    const router = useRouter()
    const basicSetting = ref({ ...defaultBasicSetting() })
    const loading = ref(false)
    const posterPageSetting = ref({})
    const calledApi = ref(false)
    const listValue = ref([])
    const localeList = ref([])
    const getLocaleList = async () => {
        try {
            const data = await localeListApi()
            if (data.length) {
                localeList.value = data
            }
        } catch (error) {
            console.log('🚀 ~ file: event.js:44 ~ getLocaleList ~ error:', error)
        }
    }

    const domainList = ref()
    const getDomainList = async () => {
        try {
            const data = await domainListApi()
            if (data.length) {
                domainList.value = data
            }
        } catch (error) {
            console.log('🚀 ~ file: event.js:56 ~ getDomainList ~ error:', error)
        }
    }
    const templateList = ref()
    const getTemplateList = async () => {
        try {
            //TODO change to dynamic way
            const data = await templateListApi(1)
            if (data.length) {
                templateList.value = data
            }
        } catch (error) {
            console.log('🚀 ~ file: event.js:68 ~ getTemplateList ~ error:', error)
        }
    }

    const getDataToPost = () => {
        const startDate = DateTime.fromISO(`${basicSetting.value.startDate}`, {
            zone: basicSetting.value.utc.tzCode,
        })
            .toUTC(0)
            .valueOf()
        const endDate = DateTime.fromISO(basicSetting.value.endDate, {
            zone: basicSetting.value.utc.tzCode,
        })
            .toUTC(0)
            .valueOf()

        const body = new FormData()

        const basicData = {
            domain_id: domainList.value[basicSetting.value.domain].id,
            event_template_id: templateList.value[basicSetting.value.layout].id,
            name: basicSetting.value.name,
            slug: basicSetting.value.slug,
            start_date: startDate,
            end_date: endDate,
            metadata: [],
        }
        if (basicSetting.value?.liveChat)
            basicData.live_chat_collection_id = basicSetting.value?.liveChatId

        for (const key in basicSetting.value.localeMeta) {
            if (Object.hasOwnProperty.call(basicSetting.value.localeMeta, key)) {
                const item = basicSetting.value.localeMeta[key]
                basicData.metadata.push({
                    locale: key,
                    title: item.title,
                    description: item.description,
                    keyword: item.keyword,
                })
            }
        }
        const uiData = {}

        if (posterPageSetting.value) {
            const { logo, posterImage, loginLocale, registerLocale } = posterPageSetting.value

            //when new image need to upload
            if (logo?.image.length) body.append('logo', logo.image[0].file)
            let posterImageToPost = {}

            if (posterImage) {
                const image = { ...posterImage }
                Object.keys(image).forEach((key) => {
                    posterImageToPost[key] = {}
                    posterImageToPost[key]['redirect'] = image[key]['redirect']
                    if (image[key]['375px']?.image.length)
                        image[key]['375px'].image.forEach((item) => {
                            const extType = item.file.type.slice(6)

                            body.append('375', item.file, key + '.' + extType)
                        })
                    else posterImageToPost[key]['375px'] = image[key]['375px']?.imageUrl
                    if (image[key]['600px']?.image.length)
                        image[key]['600px'].image.forEach((item) => {
                            const extType = item.file.type.slice(6)
                            body.append('600', item.file, key + '.' + extType)
                        })
                    else posterImageToPost[key]['600px'] = image[key]['600px']?.imageUrl
                })
            }

            //only needed when edit event
            uiData.logo_image_url = logo?.imageUrl
            uiData.logo_redirect = logo?.url

            uiData.login_redirect = loginLocale
            uiData.register_redirect = registerLocale
            uiData.poster_image = posterImageToPost
        }

        body.append('stringifiedData', JSON.stringify({ ...basicData, ui_data: uiData }))

        return body
    }

    const getUiDataToPost = () => {
        const { logo, posterBackground, loginLocale } = posterPageSetting.value
        const body = new FormData()
        if (logo?.image.length) body.append('logo', logo.image[0].file)
        if (posterBackground?.mobile.length) body.append('375', posterBackground.mobile[0].file)
        if (posterBackground?.pad.length) body.append('600', posterBackground.pad[0].file)

        const dataToPost = {}
        if (logo?.url) dataToPost.logo_redirect = logo.url
        if (posterBackground?.redirect) dataToPost.poster_redirect = posterBackground.redirectUrl

        dataToPost.login_redirect = loginLocale
        return body
    }

    const $reset = () => {
        console.log('resetting')
        basicSetting.value = { ...defaultBasicSetting() }
        loading.value = false
        posterPageSetting.value = null
        // reset it to call another event
        calledApi.value = false
    }

    const getDataByEventContentId = async (eventContentId) => {
        try {
            // each event page only call api once
            if (!calledApi.value) calledApi.value = true
            else return
            loading.value = true
            const { data } = await eventMe(eventContentId)
            //TODO these 3 api call was called on onBeforeMount,
            //but it having race condition on edit page,
            //when user refresh the page
            //or browse the edit page at initial
            //localeMeta, templateList, domainList are undefined
            //so need to call the api again.
            if (!localeList.value?.length) {
                await getDomainList()
                await getTemplateList()
                await getLocaleList()
            }
            if (data) {
                const localeMeta = {}

                const locale = []

                if (data.locale.length) {
                    data.locale.forEach((item) => {
                        localeMeta[item.locale] = {
                            title: item.title,
                            description: item.description,
                            keyword: item.keyword,
                        }
                        locale.push(localeList.value.findIndex((x) => x.short_code === item.locale))
                    })
                }

                let uiData
                let posterImage = {}
                switch (data.template_name) {
                    case 'Image Landing':
                        if (data.uiData.poster_image)
                            Object.keys(data.uiData.poster_image).map((key) => {
                                posterImage[key] = data.uiData.poster_image[key]
                                posterImage[key]['redirectCheck'] = data.uiData.poster_image[key][
                                    'redirect'
                                ]
                                    ? true
                                    : false
                                posterImage[key]['375px'] = {
                                    imageUrl: data.uiData.poster_image[key]['375px'],
                                    image: [],
                                }
                                posterImage[key]['600px'] = {
                                    imageUrl: data.uiData.poster_image[key]['600px'],
                                    image: [],
                                }
                            })
                        uiData = {
                            logo: {
                                imageUrl: data.uiData.logo_image_url,
                                image: [],
                                url: data.uiData.logo_redirect,
                            },
                            loginLocale: {},
                            registerLocale: {},
                            posterImage,
                        }
                        if (data.uiData.loginRedirect?.length > 0)
                            data.uiData.loginRedirect.forEach(({ locale, redirect }) => {
                                uiData.loginLocale[locale] = redirect
                            })
                        if (data.uiData.registerRedirect?.length > 0)
                            data.uiData.registerRedirect.forEach(({ locale, redirect }) => {
                                uiData.registerLocale[locale] = redirect
                            })

                        break

                    default:
                        break
                }

                basicSetting.value = {
                    name: data.name,
                    slug: data.slug,
                    domain: domainList.value.findIndex((x) => x.id === data.domain_id),
                    startDate: data.start_date.split('.')[0],
                    endDate: data.end_date.split('.')[0],
                    status: data.status,
                    layout: templateList.value.findIndex((x) => x.name === data.template_name),
                    liveChat: data?.live_chat ? 'Yes' : 'No',
                    localeMeta,
                    locale,
                    utc: 'UTC',
                }
                posterPageSetting.value = { ...uiData }
            }
        } catch (error) {
            console.log('🚀 ~ file: event.js:118 ~ getDataByEventContentId ~ error:', error)
            router.push('/event/poster_page')
        } finally {
            loading.value = false
        }
    }

    onBeforeMount(async () => {
        await getDomainList()
        await getTemplateList()
        await getLocaleList()
    })

    return {
        $reset,
        basicSetting,
        templateList,
        localeList,
        domainList,
        posterPageSetting,
        getDataToPost,
        getDataByEventContentId,
        getUiDataToPost,
        loading,
        calledApi,
    }
})
