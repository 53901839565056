<template>
    <GlobalToast />
    <router-view v-slot="{ Component }">
        <suspense timeout="0">
            <template #default>
                <component :is="Component"></component>
            </template>
            <template #fallback>
                <div>Loading...</div>
            </template>
        </suspense>
    </router-view>
</template>
<script setup>
import GlobalToast from '@/components/ui/GlobalToast.vue'
import { onBeforeMount } from 'vue'
import { getEventList } from '@/api/eventPublic'
import { useRouter } from 'vue-router'
const router = useRouter()
//TODO cannot view the route immediately
const handleCreateDynamicRoute = async () => {
    try {
        const data = await getEventList(1)
        if (data.length) {
            data.forEach((item) => {
                router.addRoute({
                    path: `/dynamic_event/${item.slug}`,
                    name: `${item.id}`,
                    meta: { templateName: item.template_name, eventName: item.name },
                    component: () => import('@/views/dynamicEvent/imageLandingByID'),
                })
            })
        }
    } catch (error) {
        console.log('🚀 ~ file: App.vue:34 ~ handleCreateDynamicRoute ~ error:', error)
    }
}
onBeforeMount(() => {
    // handleCreateDynamicRoute()
})
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
@import './index.css';
</style>
