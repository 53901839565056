<template>
    <CFooter>
        <div>
            <a href="https://www.dooprime.com" target="_blank">CMS V2 Dashboard</a>
            <span class="ms-1">&copy; {{ new Date().getFullYear() }} Doo.</span>
        </div>
        <div>
            <span class="me-1" target="_blank">Powered by</span>
            <a href="https://www.dooprime.com">Doo</a>
        </div>
    </CFooter>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>

<style lang="scss">
.footer {
    background:none;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 0;
    padding: 12px 24px 30px;
    a, span {
        color: #455473;
        font-size: 12px;
        font-weight: bold;
    }
    span {
        text-decoration: none;
    }
}
</style>
