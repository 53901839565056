<template>
    <CToaster placement="top-end">
        <CToast v-for="(toast, index) in commonStore.toasts" :key="index">
            <CToastHeader closeButton>
                <span class="me-auto fw-bold">{{ toast.title }}</span>
                <!-- <small>7 min ago</small> -->
            </CToastHeader>
            <CToastBody>
                {{ toast.content }}
            </CToastBody>
        </CToast>
    </CToaster>
</template>

<script>
import { useCommonStore } from '@/stores/common'

export default {
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
}
</script>
