<template>
    <div>
        <div class="tools-wrapper wrapper d-flex min-vh-100">
            <SidePanel />
            <MainModal />
            <div class="body">
                <!-- <CContainer lg> -->
                <router-view />
                <!-- </CContainer> -->
            </div>
        </div>
    </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import SidePanel from '@/components/SidePanel.vue'
import MainModal from '@/components/modals/index.vue'
// import AppSidebar from '@/components/AppSidebar.vue'

export default {
    name: 'DynamicDownloadLinkLayout',
    components: {
        SidePanel,
        MainModal,
        // AppSidebar,
        CContainer,
    },
}
</script>
