import api from './config'

const baseUrl = '/pog/financialAssets'

export const listFinancialAssets = async () => {
    try {
        const payload = await api({ method: 'get', url: `${baseUrl}/list` })
        return payload
    } catch (e) {
        return e
    }
}
export const findFinancialAssets = async (id) => {
    try {
        const payload = await api({ method: 'get', url: `${baseUrl}/find/${id}` })
        return payload
    } catch (e) {
        return e
    }
}
export const insertFinancialAsset = async (id, data) => {
    try {
        const payload = await api({ method: 'post', url: `${baseUrl}/insert/${id}`, data: data })
        return payload
    } catch (e) {
        return e
    }
}
export const updateFinancialAsset = async (id, data) => {
    try {
        const payload = await api({ method: 'put', url: `${baseUrl}/update/${id}`, data: data })
        return payload
    } catch (e) {
        return e
    }
}
export const removeFinancialAsset = async (id) => {
    try {
        const payload = await api({ method: 'delete', url: `${baseUrl}/delete/${id}` })
        return payload
    } catch (e) {
        return e
    }
}
