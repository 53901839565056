<template>
    <div class="user-wrapper wrapper d-flex min-vh-100">
        <SidePanel />

        <div class="w-full bg-[#f0f2f5]">
            <div
                class="fixed top-0 z-50 flex h-screen w-full bg-black bg-opacity-50"
                v-if="loading"
            >
                <p class="m-auto">Loading...</p>
            </div>
            <div class="m-2 flex w-full flex-col bg-white py-3 pl-10">
                <div class="flex flex-row">
                    <div v-for="(item, index) in $route.meta.breadcrumb" :key="item.name">
                        <div :href="item.href" v-if="index !== $route.meta.breadcrumb.length - 1">
                            <router-link :to="item.href" class="text-gray-400 no-underline">{{
                                item.name
                            }}</router-link>
                            <span class="px-2">/</span>
                        </div>
                        <div v-else>
                            <span>{{ item.name }}</span>
                            <span class="px-2"></span>
                        </div>
                    </div>
                </div>
                <h1 class="pt-2 text-2xl font-medium">
                    {{ $route.name }}
                </h1>
            </div>
            <router-view />
        </div>
    </div>
</template>
<script setup>
import SidePanel from '@/components/SidePanel.vue'
import { useEventStore } from '@/stores/event'
import { storeToRefs } from 'pinia'

const eventStore = useEventStore()
const { loading } = storeToRefs(eventStore)
</script>
