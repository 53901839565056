<template>
    <iframe class="sampleIframe" :src="`https://v5-crm-client-uat.zeusenv.com/signup/index?hl=en`"></iframe>
</template>
<script lang="ts"></script>
<style scoped>
    .sampleIframe {
        display: block;  /* iframes are inline by default */
        height: 100vh;  /* Set height to 100% of the viewport height */
        width: 100vw;  /* Set width to 100% of the viewport width */
        border: none; /* Remove default border */
        background: lightyellow; /* Just for styling */
    }
</style>
