import api from './config'

const baseUrl = '/event/domain'

export const domainList = async () => {
    try {
        const payload = await api({ method: 'get', url: `${baseUrl}/` })
        return payload.data
    } catch (e) {
        return e
    }
}

export const domainMe = async (id) => {
    try {
        const payload = await api({ method: 'get', url: `${baseUrl}/${id}` })
        return payload
    } catch (e) {
        return e
    }
}
