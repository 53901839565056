/**
 * A little plugin that automatically scrolls to the first error.
 **/
const scrollToErrors = (node) => {
    if (node.props.type === 'form') {
        const scrollTo = (node) => {
            const el = document.getElementById(node.props.id)
            if (el) {
                el.scrollIntoView()
            }
        }

        const scrollToErrors = () => {
            node.walk((child) => {
                // Check if this child has errors
                if (child.ledger.value('blocking') || child.ledger.value('errors')) {
                    // We found an input with validation errors
                    scrollTo(child)
                    // Stop searching
                    return false
                }
            }, true)
        }

        node.props.onSubmitInvalid = () => {
            scrollToErrors()
        }
        node.on('unsettled:errors', scrollToErrors)
    }
    return false
}

export default [scrollToErrors]
